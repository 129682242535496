* {
  margin: 0;
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  color: var(--color-on-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* VARIABLES */

:root {
  --app-height: 100%;

  --typescale-headline-large-font: 'Poppins', sans-serif;
  --typescale-headline-large-weight: 600;
  --typescale-headline-large-size: 2rem;
  --typescale-headline-large-line-height: 40px;
  --typescale-headline-large-tracking: 0px;

  --typescale-headline-small-font: 'Poppins', sans-serif;
  --typescale-headline-small-weight: 600;
  --typescale-headline-small-size: 1.5rem;
  --typescale-headline-small-line-height: 32px;
  --typescale-headline-small-tracking: 0px;

  --typescale-title-large-font: 'Poppins', sans-serif;
  --typescale-title-large-weight: 600;
  --typescale-title-large-size: 1.375rem;
  --typescale-title-large-line-height: 28px;
  --typescale-title-large-tracking: 0px;

  --typescale-title-medium-font: 'Poppins', sans-serif;
  --typescale-title-medium-weight: 600;
  --typescale-title-medium-size: 1rem;
  --typescale-title-medium-line-height: 24px;
  --typescale-title-medium-tracking: 0.1px;

  --typescale-title-small-font: 'Poppins', sans-serif;
  --typescale-title-small-weight: 600;
  --typescale-title-small-size: 0.875rem;
  --typescale-title-small-line-height: 20px;
  --typescale-title-small-tracking: 0.1px;

  --typescale-body-large-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --typescale-body-large-weight: 400;
  --typescale-body-large-size: 1rem;
  --typescale-body-large-line-height: 24px;
  --typescale-body-large-tracking: 0.5px;

  --typescale-body-medium-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --typescale-body-medium-weight: 400;
  --typescale-body-medium-size: 0.875rem;
  --typescale-body-medium-line-height: 20px;
  --typescale-body-medium-tracking: 0.25px;

  --typescale-label-large-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --typescale-label-large-weight: 600;
  --typescale-label-large-size: 0.875rem;
  --typescale-label-large-line-height: 20px;
  --typescale-label-large-tracking: 0.1px;

  --typescale-label-medium-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --typescale-label-medium-weight: 500;
  --typescale-label-medium-size: 0.75rem;
  --typescale-label-medium-line-height: 16px;
  --typescale-label-medium-tracking: 0.5px;

  --shape-corner-none: none;
  --shape-corner-extra-small: 4px;
  --shape-corner-extra-small-top: 4px 4px 0px 0px;
  --shape-corner-small: 8px;
  --shape-corner-medium: 12px;
  --shape-corner-large: 16px;
  --shape-corner-large-end: 0px 16px 16px 0px;
  --shape-corner-large-top: 16px 16px 0px 0px;
  --shape-corner-extra-large: 28px;
  --shape-corner-extra-large-top: 28px 28px 0px 0px;
  --shape-corner-full: 28px;

  --color-primary: #004dec;
  --color-on-primary: #ffffff;
  --color-primary-container: #dbe1ff;
  --color-on-primary-container: #001454;
  --color-secondary: #0062a2;
  --color-on-secondary: #ffffff;
  --color-secondary-container: #cfe4ff;
  --color-on-secondary-container: #001d36;
  --color-tertiary: #715d00;
  --color-on-tertiary: #ffffff;
  --color-tertiary-container: #ffe163;
  --color-on-tertiary-container: #231b00;
  --color-error: #B3261E;
  --color-on-error: #FFFFFF;
  --color-error-container: #F9DEDC;
  --color-on-error-container: #410E0B;
  --color-outline: #79747E66;
  --color-outline: #79747E;
  --color-background: #fefbff;
  --color-on-background: #1b1b1f;
  --color-surface: #fefbff;
  --color-on-surface: #1b1b1f;
  --color-surface-variant: #E7E0EC;
  --color-on-surface-variant: #49454F;
  --color-inverse-primary: #b5c4ff;
  --color-inverse-surface: #303033;
  --color-inverse-on-surface: #f3f0f5;

  --color-neutral: #ffffff;

  --color-surface-elevation-0: #004dec00;
  --color-surface-elevation-1: #004dec0d;
  --color-surface-elevation-2: #004dec14;
  --color-surface-elevation-3: #004dec1c;
  --color-surface-elevation-4: #004dec1f;
  --color-surface-elevation-5: #004dec24;

  --color-on-surface-elevation-0: #1b1b1f00;
  --color-on-surface-elevation-1: #1b1b1f0d;
  --color-on-surface-elevation-2: #1b1b1f14;
  --color-on-surface-elevation-3: #1b1b1f1c;
  --color-on-surface-elevation-4: #1b1b1f1f;
  --color-on-surface-elevation-5: #1b1b1f24;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-primary: #b5c4ff;
    --color-on-primary: #002583;
    --color-primary-container: #0038b8;
    --color-on-primary-container: #dbe1ff;
    --color-secondary: #9acbff;
    --color-on-secondary: #003257;
    --color-secondary-container: #00497b;
    --color-on-secondary-container: #cfe4ff;
    --color-tertiary: #eac300;
    --color-on-tertiary: #3b2f00;
    --color-tertiary-container: #554500;
    --color-on-tertiary-container: #ffe163;
    --color-error: #F2B8B5;
    --color-on-error: #601410;
    --color-error-container: #8C1D18;
    --color-on-error-container: #F9DEDC;
    --color-outline: #938F9966;
    --color-outline: #938F99;
    --color-background: #1b1b1f;
    --color-on-background: #e4e2e6;
    --color-surface: #1b1b1f;
    --color-on-surface: #e4e2e6;
    --color-surface-variant: #49454F;
    --color-on-surface-variant: #CAC4D0;
    --color-inverse-primary: #004dec;
    --color-inverse-surface: #e4e2e6;
    --color-inverse-on-surface: #1b1b1f;


    --color-neutral: #000000;

    --color-surface-elevation-0: #b5c4ff00;
    --color-surface-elevation-1: #b5c4ff0d;
    --color-surface-elevation-2: #b5c4ff14;
    --color-surface-elevation-3: #b5c4ff1c;
    --color-surface-elevation-4: #b5c4ff1f;
    --color-surface-elevation-5: #b5c4ff24;

    --color-on-surface-elevation-0: #e4e2e600;
    --color-on-surface-elevation-1: #e4e2e60d;
    --color-on-surface-elevation-2: #e4e2e614;
    --color-on-surface-elevation-3: #e4e2e61c;
    --color-on-surface-elevation-4: #e4e2e61f;
    --color-on-surface-elevation-5: #e4e2e624;
  }

  html {
    color-scheme: dark;
  }
}

@media only screen and (min-width: 960px) {

  :root {
    --typescale-body-medium-size: 1rem;
    --typescale-body-medium-line-height: 24px;
  }

}

/* TYPOGRAPHY */

h1 {
  font-family: var(--typescale-headline-large-font);
  font-weight: var(--typescale-headline-large-weight);
  font-size: var(--typescale-headline-large-size);
  line-height: var(--typescale-headline-large-line-height);
  letter-spacing: var(--typescale-headline-large-tracking);
  margin-bottom: 1.5rem;
}


h2 {
  font-family: var(--typescale-headline-small-font);
  font-weight: var(--typescale-headline-small-weight);
  font-size: var(--typescale-headline-small-size);
  line-height: var(--typescale-headline-small-line-height);
  letter-spacing: var(--typescale-headline-small-tracking);
}

h3 {
  font-family: var(--typescale-title-large-font);
  font-weight: var(--typescale-title-large-weight);
  font-size: var(--typescale-title-large-size);
  line-height: var(--typescale-title-large-line-height);
  letter-spacing: var(--typescale-title-large-tracking);
}

h4 {
  font-family: var(--typescale-title-medium-font);
  font-weight: var(--typescale-title-medium-weight);
  font-size: var(--typescale-title-medium-size);
  line-height: var(--typescale-title-medium-line-height);
  letter-spacing: var(--typescale-title-medium-tracking);
}

h5 {
  font-family: var(--typescale-title-small-font);
  font-weight: var(--typescale-title-small-weight);
  font-size: var(--typescale-title-small-size);
  line-height: var(--typescale-title-small-line-height);
  letter-spacing: var(--typescale-title-small-tracking);
}

p {
  font-family: var(--typescale-body-medium-font);
  font-weight: var(--typescale-body-medium-weight);
  font-size: var(--typescale-body-medium-size);
  line-height: var(--typescale-body-medium-line-height);
  letter-spacing: var(--typescale-body-medium-tracking);
}

a {
  text-decoration: none;
  font-family: var(--typescale-body-medium-font);
  font-weight: var(--typescale-body-medium-weight);
  /* font-size: var(--typescale-body-medium-size); */
  line-height: var(--typescale-body-medium-line-height);
  letter-spacing: var(--typescale-body-medium-tracking);
}

/* a:link {
  color: var(--color-primary);
}
a:visited {
  color: var(--color-secondary);
}
a:hover {
  color: var(--color-primary);
} */

table {
  font-family: var(--typescale-body-medium-font);
  font-weight: var(--typescale-body-medium-weight);
  font-size: var(--typescale-body-medium-size);
  line-height: var(--typescale-body-medium-line-height);
  letter-spacing: var(--typescale-body-medium-tracking);
}

thead {
  font-family: var(--typescale-title-medium-font);
  font-weight: var(--typescale-title-medium-weight);
  font-size: var(--typescale-title-medium-size);
  line-height: var(--typescale-title-medium-line-height);
  letter-spacing: var(--typescale-title-medium-tracking);
}


/* BUTTONS */

button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  font-family: var(--typescale-label-large-font);
  font-weight: var(--typescale-label-large-weight);
  font-size: var(--typescale-label-large-size);
  line-height: var(--typescale-label-large-line-height);
  letter-spacing: var(--typescale-label-large-tracking);
}

button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

button:disabled {
  background: var(--color-surface-variant);
  color: var(--color-on-surface-variant);
  opacity: 0.5;
}

/* FILLED BUTTON */

.filled-button {
  height: 40px;
  padding: 0 1.5rem;
  border-radius: var(--shape-corner-full);
  background: var(--color-primary);
  color: var(--color-on-primary);
}

.filled-button:hover::before {
  background: var(--color-on-surface-elevation-2);
}

.filled-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.filled-button:focus::before {
  background: var(--color-on-surface-elevation-3);
}

.filled-button:active::before {
  background: var(--color-on-surface-elevation-5);
}

.filled-button svg {
  margin: 0 0.5rem 0 -0.5rem;
}

.filled-button span {
  margin: 0 0.5rem 0 -0.5rem;
}

/* FILLED TONAL BUTTON */

.filled-tonal-button {
  height: 40px;
  padding: 0 1.5rem;
  border-radius: var(--shape-corner-full);
  background: var(--color-secondary-container);
  color: var(--color-on-secondary-container);
}

.filled-tonal-button:hover::before {
  background: var(--color-surface-elevation-2);
}

.filled-tonal-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.filled-tonal-button:focus::before {
  background: var(--color-surface-elevation-3);
}

.filled-tonal-button:active::before {
  background: var(--color-surface-elevation-5);
}

.filled-tonal-button svg {
  margin: 0 0.5rem 0 -0.5rem;
}

.filled-tonal-button span {
  margin: 0 0.5rem 0 -0.5rem;
}

/* ELEVATED BUTTON */

.elevated-button {
  height: 40px;
  padding: 0 1.5rem;
  border-radius: var(--shape-corner-full);
  background: var(--color-surface);
  background: transparent;
  color: var(--color-on-surface);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.elevated-button::before {
  background: var(--color-surface-elevation-1);
}

.elevated-button:hover::before {
  background: var(--color-surface-elevation-2);
}

.elevated-button:focus::before {
  background: var(--color-surface-elevation-3);
}

.elevated-button:active::before {
  background: var(--color-surface-elevation-5);
}

.elevated-button svg {
  margin: 0 0.5rem 0 -0.5rem;
}

.elevated-button span {
  margin: 0 0.5rem 0 -0.5rem;
}

/* TEXT BUTTON */

.text-button {
  height: 40px;
  padding: 0 0.75rem;
  color: var(--color-primary);
  background: none;
}

.text-button span {
  margin: 0 0.5rem 0 -0.5rem;
}

/* FAB */

.fab {
  height: 56px;
  width: 56px;
  border-radius: var(--shape-corner-large);
  background: var(--color-tertiary-container);
  color: var(--color-on-tertiary-container);
}

.fab:hover::before {
  background: var(--color-surface-elevation-1);
}

.fab:focus::before {
  background: var(--color-surface-elevation-3);
}

.fab:active::before {
  background: var(--color-surface-elevation-5);
}


/* SEGMENTED BUTTON */

.segmented-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* width: 360px; */
}

.segmented-button {
  height: 48px;
  border: 1px solid var(--color-outline);
  color: var(--color-on-surface);
  z-index: 1;
}

.segmented-button:first-child {
  border-right: none;
  border-radius: 24px 0 0 24px;
}

.segmented-button:last-child {
  border-left: none;
  border-radius: 0 24px 24px 0;
}

.segmented-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--color-secondary-container);
  opacity: 0;
  z-index: -1;
}

.segmented-button:hover::after {
  opacity: 0.4;
}

.segmented-button.active {
  color: var(--color-on-secondary-container);
}

.segmented-button.active::after {
  opacity: 1;
}

.segmented-button span {
  margin-left: -0.25rem;
  margin-right: 0.25rem;
}

/* EXTENDED FAB */

.extended-fab {
  height: 56px;
  padding: 0 1rem;
  border-radius: var(--shape-corner-large);
  background: var(--color-tertiary-container);
  color: var(--color-on-tertiary-container);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.extended-fab:hover::before {
  background: var(--color-surface-elevation-1);
}

.extended-fab:focus::before {
  background: var(--color-surface-elevation-3);
}

.extended-fab:active::before {
  background: var(--color-surface-elevation-5);
}

.extended-fab svg {
  margin-right: 0.5rem;
}

.extended-fab span {
  margin-right: 0.5rem;
}

/* ICON FAB */

.icon-button {
  padding: 8px;
  margin: 4px;
  border-radius: var(--shape-corner-full);
  background: var(--color-surface-elevation-1);
  color: var(--color-on-background);
}

.icon-button:hover {
  background: var(--color-surface-elevation-2);
}

/* INPUT */

input {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  outline: none;
  background: inherit;
}

select {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  outline: none;
  background: inherit;
  height: 3rem;
  width: 100%;
  border: 1px solid var(--color-outline);
  border-radius: var(--shape-corner-small);
  padding: 0 1rem;
  color: var(--color-on-surface);
  background-color: var(--color-surface);
}

@media (prefers-color-scheme: dark) {

  input,
  select,
  option {
    color-scheme: dark;
  }
}

.input-container {
  position: relative;
}

.input-normal {
  height: 3rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid var(--color-outline);
  /* border: none; */
  border-radius: var(--shape-corner-extra-small);
  border-radius: var(--shape-corner-small);
  font-family: var(--typescale-body-large-font);
  font-weight: var(--typescale-body-large-weight);
  font-size: var(--typescale-body-large-size);
  line-height: var(--typescale-body-large-line-height);
  letter-spacing: var(--typescale-body-large-tracking);
  color: var(--color-on-surface);
  background-color: var(--color-surface);
  /* background: var(--color-surface-elevation-1); */
}

.input-normal:focus {
  border: 2px solid var(--color-on-primary-container);
}

.input-normal:hover:not(:focus) {
  border-color: var(--color-surface-variant);
}

.input-label {
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  font-size: 1rem;
  transition: top 0.2s, font-size 0.2s;
  background-color: inherit;
  background-color: var(--color-surface);
  border-color: var(--color-on-surface);
  padding: 0 0.25rem;
}

.input-normal:focus+.input-label {
  top: -0.5rem;
  font-size: 0.875rem;
  color: var(--color-primary);
}

.input-normal:not([value=""]):not(:focus)+.input-label {
  top: -0.5rem;
  font-size: 0.875rem;
  color: #5f6368;
}

.input-icon {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--gray-500);
  cursor: pointer;
}

input[type="radio"] {
  margin: 0.5rem;
}

fieldset {
  padding: 1rem;
  border: 1px solid var(--color-outline);
  border-radius: var(--shape-corner-small);
  display: grid;
  grid-column: 1rem;
  gap: 1rem;
}

fieldset input {
  width: 100%;
}

fieldset select {
  width: 100%;
}

legend {
  font-weight: 500;
}

textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--color-outline);
  border-radius: var(--shape-corner-extra-small);
  border-radius: var(--shape-corner-small);
  font-family: var(--typescale-body-large-font);
  font-weight: var(--typescale-body-large-weight);
  font-size: var(--typescale-body-large-size);
  line-height: var(--typescale-body-large-line-height);
  letter-spacing: var(--typescale-body-large-tracking);
  color: var(--color-on-surface);
  background-color: var(--color-surface);
  resize: vertical;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  -webkit-text-fill-color: var(--color-on-surface);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-surface-elevation-5) inset;
  transition: background-color 5000s ease-in-out 0s;
}



/* TABLE */

.table-container {
  background: var(--color-surface);
  overflow-x: auto;
  margin: 2rem 0;
}

table {
  width: 100%;
  background: var(--color-surface-elevation-1);
  color: var(--color-on-primary-container);
  border-radius: var(--shape-corner-large);
  border-collapse: separate;
  -webkit-print-color-adjust: exact;
  font-size: small;
}

thead tr {
  height: 56px;
  white-space: nowrap;
}

th {
  padding: 0 1rem;
  background: var(--color-surface-elevation-2);
}

th:first-child {
  border-radius: 16px 0 0 0;
}

th:last-child {
  border-radius: 0 16px 0 0;
}

tbody tr {
  height: 48px;
}

tbody tr:hover {
  background: var(--color-surface-elevation-1);
}

td {
  padding: 0.5rem 1rem;
  /* display: flex; */
  /* align-items: center; */
  /* gap: 0.5rem; */
}


#middle {
  border-radius: 16px;
  overflow: auto;
}

@media only screen and (min-width: 600px) {

  th {
    padding: 0 2rem;
  }

  td {
    padding: 0.5rem 2rem;
  }
}

/* TOGGLE SWITCH */

.toggle-switch-on {
  height: 14px;
  width: 36px;
  border-radius: 7px;
  background-color: var(--color-primary-container);
  position: relative;
}

.toggle-switch-off {
  height: 14px;
  width: 36px;
  border-radius: 7px;
  background-color: var(--color-surface-variant);
  position: relative;
}

.toggle-switch-circle-on {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--color-primary);
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 0;
}

.toggle-switch-circle-off {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--color-outline);
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
}

/* DIALOG */


.dialog {
  background: var(--color-primary-container);
}

/* hr */

hr {
  border: 0;
  height: 1px;
  background: var(--color-outline);
  margin-block: 1.5rem;
}